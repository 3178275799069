import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../../../../components/formComponents/input";
import { FormSelect } from "../../../../../../components/formComponents/select";
import { FormTextArea } from "../../../../../../components/formComponents/textarea/textarea";
import {
  valid_after_open_units_ru,
  valid_after_open_units_uz,
} from "../../../../constants";
import { useMyform } from "./useMyform";
export const PreparationForms = () => {
  const {
    props,
    isUz,
    isRegistered,
    lang_props,
    crops,
    cropLoading,
    shapes,
    shapeLoading,
  } = useMyform();
  const { t } = useTranslation();
  const valid_after_open_units = isUz
    ? valid_after_open_units_uz
    : valid_after_open_units_ru;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24} md={12}>
        <FormInput
          {...lang_props}
          label={t("mineral.name")}
          placeholder={t("mineral.name")}
          name="product.name"
        />
      </Col>
      <Col span={24} md={12}>
        <FormInput
          {...lang_props}
          label={t("mineral.patentName")}
          placeholder={t("mineral.patentName")}
          name="product.generic_name"
        />
      </Col>
      <Col span={24} md={12}>
        <FormInput
          {...lang_props}
          label={t("mineral.activeSubs")}
          placeholder={t("mineral.activeSubs")}
          name="product.substances_string"
        />
      </Col>
      <Col span={24} md={12}>
        <FormSelect
          {...props}
          multiple={true}
          label={t("mineral.crop")}
          placeholder={t("inner-fcc.tanla")}
          name="product.for_crop"
          options={crops?.filter((c) =>
            isRegistered ? c.value !== 83 : c.value === 83
          )}
          loading={cropLoading}
        />
      </Col>
      <Col span={24} md={12}>
        <FormSelect
          {...props}
          label={t("mineral.shape")}
          placeholder={t("inner-fcc.tanla")}
          name="product.product_shape"
          options={shapes}
          loading={shapeLoading}
        />
      </Col>

      <Col span={24} md={12}>
        <FormInput
          {...lang_props}
          label={t("mineral.dose")}
          placeholder={t("mineral.dose")}
          name="product.spend_rate"
        />
      </Col>

      <Col span={24} md={12}>
        <FormInput
          {...lang_props}
          label={t("mineral.concent")}
          placeholder={t("mineral.concent")}
          name="product.concentration"
        />
      </Col>

      {/* <Col span={24} md={12}>
        <FormInput
          {...lang_props}
          label={t("mineral.harm")}
          placeholder={t("mineral.harm")}
          name="product.harmful_org"
        />
      </Col> */}

      <Col span={24} md={12}>
        <FormInput
          {...lang_props}
          label={t("mineral.method")}
          placeholder={t("mineral.method")}
          name="product.use_method"
        />
      </Col>

      <Col span={24} md={12}>
        <FormInput
          {...lang_props}
          label={t("mineral.period")}
          placeholder={t("mineral.period")}
          name="product.use_period"
        />
      </Col>

      <Col span={24} md={12}>
        <FormInput
          {...lang_props}
          label={t("mineral.repeat")}
          placeholder={t("mineral.repeat")}
          name="product.use_repeat"
        />
      </Col>

      <Col span={24} md={12}>
        <Row gutter={16}>
          <Col span={16} md={18}>
            <FormInput
              {...props}
              type="number"
              label={t("mineral.validOpen")}
              min={0}
              max={999999}
              placeholder={t("mineral.validOpen")}
              name="product.valid_after_open"
            />
          </Col>
          <Col span={8} md={6}>
            <FormSelect
              {...props}
              label={t("inner-fcc.tanla")}
              placeholder={t("inner-fcc.tanla")}
              name="product.valid_after_open_unit"
              options={valid_after_open_units}
            />
          </Col>
        </Row>
      </Col>

      <Col span={24} md={12}>
        <Row gutter={16}>
          <Col span={16} md={18}>
            <FormInput
              {...props}
              type="number"
              min={0}
              max={999999}
              label={t("mineral.valid")}
              placeholder={t("mineral.valid")}
              name="product.valid_period"
            />
          </Col>
          <Col span={8} md={6}>
            <FormSelect
              {...props}
              label={t("inner-fcc.tanla")}
              placeholder={t("inner-fcc.tanla")}
              name="product.valid_period_unit"
              options={valid_after_open_units}
            />
          </Col>
        </Row>
      </Col>

      {/* <Col span={24} md={12}>
        <FormInput
          {...lang_props}
          label={t("mineral.before_harvest_day")}
          placeholder={t("mineral.before_harvest_day")}
          name="product.before_harvest_day"
        />
      </Col> */}
      <Col span={24}>
        <FormTextArea
          {...lang_props}
          label={t("mineral.keepInfo")}
          name="product.valid_saving_info"
        />
      </Col>
    </Row>
  );
};
