import React from "react";
import { PrintPaperComponent } from "../../../../../../components/ui/PaperPrint";
import { Spacer, Stack } from "../../style";
import { QRCode as LogQRCode } from "react-qrcode-logo";
import IMGLOGO from "../../../../../../assets/images/main-logo.webp";
import { useFormContext } from "react-hook-form";

export const PageThree = () => {
  const { getValues } = useFormContext();
  const data = getValues("data");
  return (
    <PrintPaperComponent page={3} qrCode={true}>
      <table>
        <tbody>
          <tr style={{ borderBottomColor: "transparent" }}>
            <td rowSpan={6} style={{ verticalAlign: "middle" }}>
              <div className="rotate">
                <p>II qism: Sertifikatlashtirish/Part II: Certification</p>
              </div>
            </td>
            <td
              colSpan={4}
              style={{
                borderBottomColor: "transparent",
                verticalAlign: "middle",
              }}
            >
              <h3 style={{ fontWeight: "bold" }}>
                II. Salomatlik to‘g‘risida maʼlumotlar/Health information
              </h3>
            </td>
            <td style={{ width: "140px" }}>
              <p>II.a Sertifikat raqami/Certificate reference</p>
              <p>{data.number}</p>
            </td>
            <td style={{ width: "100px" }} className="diagonalCross">
              <p>
                II.b
                {/* РНАБТ рақами/IMSOC reference */}
              </p>
              {/* <p>23213123</p> */}
            </td>
          </tr>

          <tr>
            <td colSpan={6}>
              <Spacer className="texts">
                <Stack
                  style={{
                    display: "grid",
                    gap: "16px",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  <h3 style={{ fontWeight: "bold" }}>
                    II.1. Salomatlik sertifikatsiyasi
                  </h3>
                  <h3 style={{ fontWeight: "bold" }}>
                    II.1 . Public health certification
                  </h3>
                </Stack>

                <Stack
                  style={{
                    display: "grid",
                    gap: "8px",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  <p>
                    Men quyida imzo chekkan holda, Yevropa Parlamenti va
                    Kengashining 178/2002 sonli (YEK) va Yevropa parlamenti va
                    kengashining 852/2004 sonli (YEK) reglamentining tegishli
                    talablaridan xabardorman va quyidagilarni tasdiqlayman:
                  </p>
                  <p>
                    I, the undersigned, hereby declare that I am aware of the
                    relevant requirements of Regulation (EC) No 178/2002 of the
                    European Parliament and of the CouncilA and Regulation (EC)
                    No 852/2004 of the European Parliament and of the CouncilB
                    and hereby confirm that:
                  </p>
                </Stack>

                <Stack
                  style={{
                    display: "grid",
                    gap: "8px",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  <p>
                    II.1.1. I-qismida qayd qilingan undirilgan yoki undirishga
                    mo‘ljallangan urug‘lar Yevropa Komissiyasining (YEK)
                    852/2004-son (YEK) reglamentga va xususan, birlamchi ishlab
                    chiqarish uchun umumiy gigiyena va 1-ilovaning A-qismida
                    belgilangan tegishli chora-tadbirlarga muvofiq talablariga
                    muvofiq ishlab chiqarilgan;
                  </p>
                  <p>
                    II.1.1 the sprouts and seeds intended for the production of
                    sprouts described in Part l have been produced under
                    conditions in conformity with Regulation (EC) No 852/2004
                    and in particular the general hygiene requirements for
                    primary production and related operations laid down in Part
                    A of Annex l thereto;
                  </p>
                </Stack>

                <Stack
                  style={{
                    display: "grid",
                    gap: "8px",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  <p>
                    II.1.2<sup>(1)</sup> undirilgan urug‘lar Yevropa
                    Ittifoqining (YEI) 210/2013<sup>C</sup> -sonli Komissiya
                    Reglamentining 2-moddasida belgilangan talablarga muvofiq
                    tasdiqlangan korxonalarda ishlab chiqarilgan;
                  </p>
                  <p>
                    II.1.2<sup>(1)</sup> the sprouts have been produced in
                    establishments approved in accordance with the requirements
                    laid down in Article 2 of Commission Regulation (EU) No
                    210/2013<sup>C</sup>;
                  </p>
                </Stack>

                <Stack
                  style={{
                    display: "grid",
                    gap: "8px",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  <p>
                    II.1.3<sup>(1)</sup> undirilgan urug‘lar YEIning
                    208/2013-son Kommisiya Reglamentida ko‘rsatilgan kuzatuv
                    talablariga javob beradigan sharoitlarda ishlab chiqarilgan
                    va 2073/2005
                    <sup>D</sup>-son Komissiya Reglamentining (YEI) 1-ilovasida
                    keltirilgan mezonlarga mos keladi.
                  </p>
                  <p>
                    II.1.3 <sup>(1)</sup> the sprouts have been produced under
                    conditions that comply with the traceability requirements
                    set out in Commission Implementing Regulation (EU) No
                    208/2013 and meet the criteria set out in Annex l to
                    Commission Regulation (EU) No 2073/2005 <sup>D</sup>.
                  </p>
                </Stack>

                <Stack
                  className="subs"
                  style={{
                    marginTop: "4px",
                    display: "grid",
                    gap: "8px",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  <p>
                    <sup>A</sup>Yevropa Parlamenti va Kengashining 2002 yil 28
                    yanvardagi oziq-ovqat to‘g‘risidagi qonunchilikning umumiy
                    prinsiplari va talablarini belgilovchi, Yevropa Oziq-ovqat
                    xavfsizligi bo‘yicha vakolatli organini tashkil etish va
                    oziq-ovqat masalalari bo‘yicha chora-tadbirlarni belgilovchi
                    178/2008-son Reglamenti (YEK) (OJL31, 1.2.2002, 1 bet);
                  </p>
                  <p>
                    <sup>A</sup>Regulation (EC) No 178/2002 of the European
                    Parliament and of the Council of 28 January 2002 laying down
                    the general principles and requirements of food law,
                    establishing a European Food Safety Authority and laying
                    down procedures in matters of food safety (OJL 31, 1.2.2002
                    , page 1);
                  </p>
                </Stack>

                <Stack
                  className="subs"
                  style={{
                    display: "grid",
                    gap: "8px",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  <p>
                    <sup>B</sup>Evropa Parlamenti va Kengashining 2004 yil 29
                    apreldagi 852/2004-sonli oziq-ovqat gigiyenasi to‘g‘risidagi
                    Reglamenti (OJL 139, 30.4.2004, 1-bet);
                  </p>
                  <p>
                    <sup>B</sup>Regulation (EC) No 852/2004 of the European
                    Parliament and of the Council of 29 April 2004 on food
                    hygiene (OJL 139, 30.4.2004, p . 1);
                  </p>
                </Stack>

                <Stack
                  className="subs"
                  style={{
                    display: "grid",
                    gap: "8px",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  <p>
                    <sup>C</sup>Evropa Parlamenti va Kengashining (OJL 68,
                    12.3.2013, 24-bet) № 852/2004-sonli reglamentga (YEK)
                    muvofiq, urug‘larni undiradigan korxonalarni tashkil etish
                    to‘g‘risidagi 2013 yil 11 martdagi 210/2013-sonli Komissiya
                    Reglamenti (YEK);
                  </p>
                  <p>
                    <sup>C</sup>Commission Regulation (EC) No 210/2013 of 11
                    March 2013 approving establishments producing sprouts, in
                    accordance with Regulation (EC) No 852/2004 of the European
                    Parliament and of the Council (OJL 68, 12.3.2013, p. 24);
                  </p>
                </Stack>

                <Stack
                  className="subs"
                  style={{
                    display: "grid",
                    gap: "8px",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  <p>
                    <sup>D</sup>2005 yil 15 noyabrdagi Oziq-ovqat uchun
                    mikrobiologik mezonlar to‘g‘risidagi (OJL 338, 22.12.2005,
                    1-bet) 2073/2005-son Komissiyasi Reglamenti (YEI).
                  </p>
                  <p>
                    <sup>D</sup>Commission Regulation (EC) No 2073/2005 of 15
                    November 2005 on microbiological criteria for food (OJL 338,
                    22.12.2005, p. 1).
                  </p>
                </Stack>
              </Spacer>
            </td>
          </tr>

          <tr>
            <td colSpan={3} style={{ minWidth: "250px" }}>
              <h3 style={{ fontWeight: "bold" }}>
                I qism/Part I: I.27 bandga havola/Box reference I.27
              </h3>
            </td>
            <td colSpan={3}>
              <Spacer>
                <p>
                  Tegishli TIF TN kodlarini ko‘rsating /Insert the appropriate
                  HS code(s):
                </p>
                <p>
                  (0704 90,0706 90, 0708 10, 0708 20, 0708 90, 0713 33,0713 34,
                  0713 39, 0713 40,0713 50, 0713 60, 0713 90, 0910 99, 1201 10,
                  1201 90, 1209 10, 1209 21 or/yoki 1209 91)
                </p>
              </Spacer>
            </td>
          </tr>

          <tr>
            <td colSpan={3} style={{ minWidth: "250px" }}>
              <h3 style={{ fontWeight: "bold" }}>
                II Qism/Part II: I.27 bandga havola/Box reference I.27
              </h3>
            </td>
            <td colSpan={3}>
              <Spacer>
                <p>Yuk tavsifi/Description of consignment:</p>
                <p>
                  Ishlab chiqarish korxonasi: undirilgan urug‘lar yoki urug‘lar
                  ishlab chiqarilgan korxona nomini kiriting/Manufacturing
                  plant: Insert the name of the establishment which produced the
                  sprouts or seeds:
                </p>
              </Spacer>
            </td>
          </tr>

          <tr>
            <td colSpan={6}>
              <h3 style={{ fontWeight: "bold" }}>Qism III/Part III</h3>
              <p style={{ fontWeight: "bold", fontStyle: "italic" }}>
                tegishliligi bo‘yicha o‘chiring (misol uchun: agar urug‘lar
                bo‘lsa)/delete as appropriate (e.g. if seeds)
              </p>
            </td>
          </tr>

          <tr>
            <td colSpan={6}>
              <Spacer>
                <Stack
                  style={{
                    display: "grid",
                    gap: "16px",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  <div>
                    <p style={{ fontWeight: "bold" }}>
                      Sertifikatlovchi xodim/Certifying officer
                    </p>
                    {/* <p>__________________</p> */}
                  </div>
                  <div>
                    <p>Mas'ul mutaxassis/Authorised Representative</p>
                    <p style={{ paddingTop: "4px" }}>MARDONOV M.</p>
                  </div>
                </Stack>
                <Stack
                  style={{
                    display: "grid",
                    gap: "16px",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  <div>
                    {/* <p style={{ marginBottom: "8px" }}>Muhr/Stamp</p> */}
                    <div
                      style={{
                        maxWidth: "80px",
                      }}
                    >
                      <LogQRCode
                        size={80}
                        fgColor="#056323"
                        logoImage={IMGLOGO}
                        ecLevel="H"
                        logoPadding={1}
                        quietZone={0}
                        logoPaddingStyle="circle"
                        logoWidth={22.5}
                        logoHeight={27}
                        qrStyle="dots"
                        eyeRadius={3}
                        style={{
                          height: "auto",
                          maxWidth: "100%",
                          width: "100%",
                        }}
                        value={window.location.href}
                      />
                    </div>
                  </div>
                  <div>
                    <p>Sana/Date</p>
                    <p>{data.given_date}</p>
                  </div>
                </Stack>
              </Spacer>
            </td>
          </tr>
        </tbody>
      </table>
    </PrintPaperComponent>
  );
};
