import React from "react";

const Cert = () => {
  return (
    <>
      <p>
        <strong>1. У</strong>
        <strong>мумий қоидалар</strong>
      </p>
      <p>
        1.1. Ўзбекистон Республикаси Фуқаролик кодексининг 370-моддасига мувофиқ
        агар куйида кўрсатилган шартлар қабул қилиниб, хизматлар учун тўлов
        амалга оширилса, қабул қилувчи юридик ёки жисмоний шахс Ариза берувчига
        айланади. Оферта шартномасини тўлиқ ва сўзсиз қабул қилиш (таклиф
        маъқулланиш) пайтида Агентлик томонидан, Ариза берувчидан Кимёлаштириш
        ва ўсимликларни ҳимоя қилиш воситаларини рўйхатга олиш гувоҳномасини
        олиш/ восита қайта рўйхатга олиш мақсадида тақдим қилинадиган ариза
        (кейинги ўринларда - ариза) электрон ҳужжат кўринишида қабул қилинади
      </p>
      <p>
        1.2. Кимёлаштириш ёки ўсимликларни ҳимоя қилиш воситасини рўйхатга
        олганлик учун, восита қайта рўйхатга олинганда йиғим ундирилади.
      </p>
      <p>
        1.3. «Электрон ҳужжат айланиши тўғрисида»ги Ўзбекистон Республикаси
        Қонунига мувофиқ электрон ҳужжат шаклидаги ариза қоғоз ҳужжатга тенг
        бўлиб, маълумотлар батафсил баён этилиши, шунингдек Ариза берувчининг
        электрон рақамли имзоси билан тасдиқланган бўлиши лозим.
      </p>
      <p>
        1.4. Ариза берувчи Оферта шартномаси (кейинги ўринларда - Шартнома)
        шартлари билан танишиб чиқгач, karantin.uz манзили бўйича электрон ариза
        юборади. Ариза берувчи Шартномада белгиланган йиғим микдорини тўлагандан
        сўнг унинг аризаси фаоллаштирилади.
      </p>
      <p>
        1.5. Ариза берувчи Шартномани белгиланган тартибда қабул қилиб, шартнома
        матнида келтириб ўтилган шаклдаги барча шартлар билан танишиб
        чиққанлигини, рози бўлганлигини ҳамда тўлик ва сўзсиз кабул килганлигини
        тасдиқлайди.
      </p>
      <p>
        1.6. Ариза берувчи ушбу хужжатнинг 2-бандида кўрсатилган тартибда
        Шартномани қабул килади ва унда белгиланган шартлар асосида Шартнома
        тузилишига розилик билдиради.
      </p>
      <p>1.7. Шартнома чақириб олинмайди.</p>
      <p>
        <strong>2.&nbsp;Шартнома предмети</strong>
      </p>
      <p>
        2.1. Мазкур шартнома шартларига мувофиқ Агентлик, кимёлаштириш ва
        ўсимликларни ҳимоя қилиш воситаларини рўйхатга олади ва рўйхатга
        олинганлиги ҳақидаги гувохномани/ воситани қайта рўйхатга олиш учун
        беради, қишлоқ хўжалигида ўсимлик зараркунандалари, касалликлари ва
        бегона ўтларга қарши фойдаланиш учун рухсат этилган кимёвий ва биологик
        ҳимоя воситалари, дефолиантлар ҳамда ўсимликларни ўсишини бошқарувчи
        воситалар рўйхатини юритади ҳамда веб-сайтида эълон қилиб боради.
      </p>
      <p>
        Ариза берувчи, Ўзбекистон Республикаси Вазирлар Маҳкамасининг 2024 йил 6
        мартдаги 115-сонли қарори билан тасдиқланган «Кимёлаштириш ва
        ўсимликларни ҳимоя қилиш воситаларини синовдан ўтказиш ҳамда рўйхатга
        олиш тартиби тўғрисидаги низом» талабига мувофиқ қуйидаги тартибда йиғим
        суммасини тўлайди:
      </p>
      <table className="doc-table">
        <thead>
          <tr>
            <th>№</th>
            <th>
              Кимёлаштириш ва ўсимликларни ҳимоя қилиш воситаларини рўйхатга
              олинганлигини тасдиқловчи гувохнома олиш / воситани қайта рўйхатга
              олиш учун
            </th>
            <th>Ҳар бир бирлик учун йиғим суммаси</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1.</td>
            <td>Кимёлаштириш воситаларини рўйхатга олиш учун</td>
            <td>базавий ҳисоблаш миқдорининг 20 баравари миқдорда.</td>
          </tr>
          <tr>
            <td>2.</td>
            <td>
              Ўсимликларни ҳимоя қилиш воситалари, микробиологик ўғитлар ва
              ўсимликларнинг ўсишини бошқарувчи воситаларни рўйхатга олиш учун
            </td>
            <td>базавий ҳисоблаш миқдорининг 200 баравари миқдорда.</td>
          </tr>
          <tr>
            <td>3.</td>
            <td>Кимёлаштириш воситаларини қайта рўйхатга олиш учун</td>
            <td>базавий ҳисоблаш миқдорининг 10 баравари миқдорда.</td>
          </tr>
          <tr>
            <td>4.</td>
            <td>
              Ўсимликларни ҳимоя қилиш воситалари, микробиологик ўғитлар ва
              ўсимликларнинг ўсишини бошқарувчи воситаларни қайта рўйхатга олиш
              учун
            </td>
            <td>базавий ҳисоблаш миқдорининг 100 баравари миқдорда.</td>
          </tr>
        </tbody>
      </table>
      <p>
        2.3. Тўлов тартиби: аризада келтирилган йиғим миқдори 100% олдиндан
        тўланади. Тўлиқ ҳисоб-китоб, гувохнома расмийлаштириш санасида амалда
        бўлган базавий ҳисоблаш миқдоридан келиб чиқиб, йиғим ҳисобланади.
      </p>
      <p>
        2.4. Агар йиғим белгиланган кунда тўланмаса, ариза берувчининг аризаси
        бекор қилинади ва уни қабул қилиш амалга оширилмаган ҳисобланади.&nbsp;
      </p>
      <p>
        <strong>3. Хизмат кўрсатиш тартиби</strong>
      </p>
      <p>
        3.1. Агентлик томонидан, Кимёлаштириш ва ўсимликларни ҳимоя қилиш
        воситаларини рўйхатга олиш ҳақидаги гувоҳномани олиш ёки воситани қайта
        рўйхатга олиш учун Ариза берувчининг аризасига илова қининадиган барча
        хужжатлар, Вазирлар Маҳкамасининг 2024 йил 6 мартдаги 115-сонли қарори
        билан тасдиқланган “Кимёлаштириш ва ўсимликларни ҳимоя қилиш
        воситаларини синовдан ўтказиш ҳамда рўйхатга олиш тартиби тўғрисида»ги
        низом талабларига кўра, қабул қилинади.
      </p>
      <p>
        3.2. Агентлик, Ўзбекистон Республикаси Вазирлар Маҳкамасининг 2024 йил 6
        мартдаги 115-сонли қарори билан тасдиқланган, “Кимёлаштириш ва
        ўсимликларни ҳимоя қилиш воситаларини синовдан ўтказиш ҳамда рўйхатга
        олиш тартиби тўғрисида”ги Низомда белгиланган тартибда аризани кўриб
        чиқади ва қарор қабул қилади.
      </p>
      <p>
        3.3. Қуйидагилар кимёлаштириш ва ўсимликларни ҳимоя қилиш воситаларини
        рўйхатга олганлик тўғрисида гувоҳнома беришни рад этиш учун асос бўлиши
        мумкин:
      </p>
      <p>
        - ариза берувчи томонидан ўсимликларни ҳимоя қилиш воситалари рўйхатга
        олинганлиги тўғрисидаги гувоҳнома олиш учун талаб этилган ҳужжатлар ва
        маълумотлар камчиликларни бартараф этиш учун махсус ваколатли давлат
        органи томонидан берилган муддат ичида тўлиқ тақдим этилмаганлиги;
      </p>
      <p>
        - тақдим этилган ҳужжатлар ушбу Низом талабларини бузган ҳолда
        расмийлаштирилганлиги;
      </p>
      <p>
        - ариза берувчи томонидан тақдим этилган ҳужжатларда нотўғри ёки бузиб
        кўрсатилган маълумотларнинг мавжудлиги;
      </p>
      <p>
        - воситаларнинг инсон саломатлиги ва атроф-муҳит учун хавфлилиги
        тўғрисида илгари номаълум бўлган маълумотлар олинганлиги;
      </p>
      <p>
        - рўйхатга олиш синовларини ўтказиш методикаси (услуб)нинг бузилганлиги;
      </p>
      <p>
        - ушбу Низомга мувофиқ воситаларни рўйхатга олиш учун синовларни ўтказиш
        натижалари бўйича асослантирилган салбий хулосалар берилганлиги;
      </p>
      <p>
        - давлат экологик экспертизаси, токсикологик-гигиеник экспертиза ва
        биологик самарадорлик бўйича салбий хулоса берилганлиги;
      </p>
      <p>
        - интеллектуал мулк соҳасидаги қонунчиликка риоя этилмаганлиги ва
        бошқалар.
      </p>

      <p>
        3.4. Кимёлаштириш ва ўсимликларни ҳимоя қилиш воситалари рўйхатга
        олинганлиги тўғрисидаги гувоҳнома беришнинг рад этилганлиги ҳақидаги
        хабарнома ариза берувчига электрон шаклда рад этишнинг сабаблари ҳамда
        қонунчилик ҳужжатларининг нормалари кўрсатилган ҳолда юборилади.
      </p>
      <p>
        3.5. Агентлик аризадаги маълумотларни ошкор қилмаслик мажбуриятини
        олади. Амалдаги конунчиликка мувофиқ, асосли равишда маълумотни ошкор
        қилиш холатларида мажбурият бузилган деб хисобланмайди.
      </p>
      <p>
        <strong>4.</strong>
        <strong>&nbsp;</strong>
        <strong>К</strong>
        <strong>оррупцияга қарши қўшимча шартлар</strong>
      </p>
      <p>
        4.1. Тарафлар оферта тузишда, офертанинг амал қилиш муддатида ва ушбу
        муддат тугаганидан сўнг, оферта билан боғлиқ коррупциявий ҳаракатлар
        содир қилмасликка келишиб оладилар.
      </p>
      <p>
        4.2. Тарафлар Адлия вазирлиги, Коррупцияга қарши курашиш агентлиги,
        Савдо-саноат палатасининг 2021 йил 12 ноябрдаги 1/4671, 01-19/7284,
        7/01-08-9544-сон қарорига илова билан тасдиқланган “Хўжалик юритувчи
        субъектлар томонидан шартномалар тузишда қўллаш учун тавсивий характерга
        эга бўлган коррупцияга қарши қўшимча шартлар” (кейинги ўринларда -
        коррупцияга қарши қўшимча шартлар)га мувофиқ коррупциянинг олдини олиш
        чораларини тан олади ва уларга риоя этилиши бўйича ҳамкорликни
        таъминлайдилар.
      </p>
      <p>
        4.3. Ҳар бир тараф оферта тузилган пайтда бевосита ўзи ёки унинг ижроия
        органлари, мансабдор шахслари ва ходимлари томонидан шартнома билан
        боғлиқ муносабатлар юзасидан Қонунга хилоф равишда пул, моддий
        қийматликлар берилмаганлигини, шартнома тузилиши эвазига норасмий пул
        ёки бошқа моддий қийматликлар олинишига йўл қўйилмаганлигини, таклиф
        этилмаганлигини, уларни беришга ваъда қилинмаганлигини, шунингдек моддий
        ёки ҳар қандай турдаги имтиёз, устунликлар олинмаганлигини (келажакда бу
        турдаги ҳаракатларни амалга ошириши мумкинлиги ҳақида таассурот
        қолдирилмаганлигини) кафолатлайди.&nbsp;
      </p>
      <p>
        4.4. Тарафлар, улар томонидан шартнома доирасида жалб қилинган
        шахсларнинг (ёрдамчи пудратчи ташкилотлар, агентлар ва тарафлар назорати
        остидаги бошқа шахсларнинг) юқоридаги ҳаракатларни содир этмаслиги
        юзасидан оқилона чоралар кўради.
      </p>
      <p>
        4.5. Тарафлар уларнинг назорати остида бўлган ва улар номидан ҳаракат
        қиладиган шахслар томонидан коррупцияга қарши белгиланган мажбуриятлар
        бузилганлиги ҳолатлари ҳақида хабар берилганлиги учун уларга таъзйиқ
        ўтказилмаслигини кафолатлайдилар.
      </p>
      <p>
        4.6. Агар бир тарафга бошқа тарафнинг коррупцияга қарши қўшимча
        шартларнинг 4.4 ва 4.5-бандларида белгиланган мажбуриятларнинг
        бузилишига йўл қўйилганлиги маълум бўлиб қолса, иккинчи тарафни бу ҳақда
        зудлик билан хабардор қилиши ва ушбу тарафдан оқилона муддат ичида
        тегишли чоралар кўрилишини ва амалга оширилган ишлар юзасидан унга
        хабардор қилишини талаб қилиши шарт. Тарафнинг талаби бўйича иккинчи
        тараф томонидан қоидабузарликларни бартараф қилиш бўйича оқилона муддат
        ичида тегишли чоралар кўрилмаган ёки кўриб чиқиш натижалари ҳақида
        хабардор қилмаган тақдирда, ушбу тараф зарарни тўлиқ қоплаб беришни
        талаб қилишга ҳақли.
      </p>
      <p>5. Жавобгарлик, низоларни ҳал этиш тартиби</p>
      <p>
        Тарафлар Ўзбекистон Республикаси қонунчилигига мувофик жавобгар
        бўладилар.
      </p>
      <p>
        5.1. Шартномани бажариш вақтида форс-мажор ҳолатлари: давлат ҳокимияти
        органларининг ҳаракатлари, ёнғин, сув тошқини, зилзила, бошка табиий
        офатлар, электр энергиясининг йуқлиги, иш ташлашлар, фуқаролик урушлари,
        тартибсизликлар, шунингдек юқорида санаб ўтилмаган ҳолатлар билан
        чекланмаган ҳар қандай бошка фавқулодда ходисалар юзага келиб, шартнома
        шартлари бузилса, Агентлик жавобгарликдан озод этилади.
      </p>
      <p>5.2. Агентлик куйидагилар учун жавобгар эмас:</p>
      <p>
        -Ариза берувчига ўз хизматларидан фойдаланишни таъминлайдиган умумий
        алоқа каналлари ёки хизматларининг сифати учун;
      </p>
      <p>
        -Ариза берувчи томонидан&nbsp;karantin.uz&nbsp;сайтида оммавий тарзда
        тақдим этилган маълумотлар учун.
      </p>
      <p>
        -Ариза берувчининг Агентлик томонидан кўрсатилган хизматларга эътирози
        ёки шикоятлари <a href="mailto:info@karantin.uz">info@karantin.uz</a>{" "}
        электрон почтаси орқали кўриб чиқиш учун қабул қилинади.
      </p>
      <p>
        5.3. Низоли вазиятларни кўриб чикишда Агентлик Ариза берувчидан низога
        доир барча тегишли ҳужжатларни талаб қилишга ҳақли.
      </p>
      <p>
        5.4. Тарафлар низо ва келишмовчиликлар юзага келганда судгача низоларни
        хал этиш тартибини қўллаш мажбуриятини оладилар. Агар низони судгача ҳал
        қилишнинг имкони бўлмаса, тарафлар судга мурожаат қилиш ҳуқуқига эга.
      </p>
      <p>
        <strong>6. Алоҳида шартлар</strong>
      </p>
      <p>
        6.1. Ариза берувчи шартномани қабул қилиш ҳамда бажариш учун зарур
        бўлган барча ҳуқуқ ва ваколатларга эга.
      </p>
      <p>
        6.2. Агентлик ушбу Шартноманинг исталган шартларини ўзгартириш ёки
        тўлдириш ҳуқуқини ўз веб-сайтида барча ўзгаришларни эълон қилиш оркали
        сақлаб қолади
      </p>
      <p>
        6.3. Ушбу шартномада назарда тутилмаган барча масалалар Ўзбекистон
        Республикаси қонунчилиги меъёрлари асосида тартибга солинади.
      </p>
    </>
  );
};

export default Cert;
