import React from "react";
import { useFormContext } from "react-hook-form";
import { QRCode as LogQRCode } from "react-qrcode-logo";
import IMGLOGO from "../../../../../../assets/images/main-logo.webp";
import { PrintPaperComponent } from "../../../../../../components/ui/PaperPrint";
import { Spacer, Stack } from "../../style";
import { ActHeader } from "../header";

export const PageOne = () => {
  const { getValues } = useFormContext();

  const data = getValues("data");
  return (
    <PrintPaperComponent page={1} qrCode={true}>
      <ActHeader />
      <h2 style={{ marginTop: 12 }}>
        CERTIFICATE FOR THE IMPORT OF SPROUTS OR SEEDS INTENDED FOR THE
        PRODUCTION OF SPROUTS
      </h2>
      <Stack
        style={{
          justifyContent: "space-between",
          alignItems: "flex-end",
          padding: "8px 0",
        }}
      >
        <h3>
          Davlat/Country:
          <br />
          <b>O'zbekiston Respublikasi/Republic of Uzbekistan</b>
        </h3>
        <h3>
          {/* Raqami va sanasi/Number and date:
          <br /> */}
          {/* <b>{`${data.number}/${data.given_date}`}</b> */}
          <b>Certificate to EU</b>
        </h3>
      </Stack>
      <table>
        <tbody>
          <tr>
            <td rowSpan={8} style={{ verticalAlign: "middle" }}>
              <div className="rotate">
                <p>
                  I qism: Jo‘natilgan yuk tafsilotlari/Part I: Details of
                  dispatched consignment
                </p>
              </div>
            </td>
            <td rowSpan={3} colSpan={2}>
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>
                  I.1. Jo‘natuvchi/Eksportyor Consignor/Exporter
                </h3>
                <p>Nomi/Name: {data?.applicant_name}</p>
                <p>Manzili/Address: {data?.applicant_address}</p>
                <p>
                  Davlat/Country: O'zbekiston Respublikasi/Republic of
                  Uzbekistan
                </p>
                <p>Davlat ISO kodi/ISO country code: UZ</p>
              </Spacer>
            </td>

            <td>
              <h3 style={{ fontWeight: "bold" }}>
                I.2. Sertifikat raqami/Certificate Reference No.
              </h3>
              <p style={{ marginTop: "4px" }}>{data.number}</p>
            </td>

            <td style={{ maxWidth: "200px" }} className="diagonalCross">
              <h3 style={{ fontWeight: "bold" }}>
                I.2a.
                {/* RNABTga havola (zarur hollarda)/Reference to IMSOC (when
                necessary) */}
              </h3>

              {/* <a
                href="https://asadbekazamov.uz"
                target="_blank"
                rel="noreferrer"
              >
                https://asadbekazamov.uz
              </a> */}
            </td>
          </tr>

          <tr>
            <td>
              <h3 style={{ fontWeight: "bold" }}>
                I.3. Markaziy vakolatli organ/Central competent authority
              </h3>
              <p>AGENCY OF PLANT PROTECTION AND QUARANTINE</p>
            </td>

            <td rowSpan={2} style={{ verticalAlign: "middle" }}>
              <div
                style={{
                  maxWidth: "100px",
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                <LogQRCode
                  size={80}
                  fgColor="#056323"
                  quietZone={0}
                  ecLevel="H"
                  logoImage={IMGLOGO}
                  logoPadding={1}
                  logoPaddingStyle="circle"
                  logoWidth={22.5}
                  logoHeight={27}
                  qrStyle="dots"
                  eyeRadius={3}
                  style={{
                    height: "auto",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                  value={window.location.href}
                />
              </div>
            </td>
          </tr>

          <tr>
            <td className="diagonalCross">
              <h3 style={{ fontWeight: "bold" }}>
                I.4.
                {/* Hududiy vakolatli organ/Local competent authority */}
              </h3>
              {/* <p>Lorem ipsum dolor sit.</p> */}
            </td>
          </tr>

          <tr>
            <td colSpan={2}>
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>
                  I.5. Qabul qiluvchi/Importyor Consignee/Importer
                </h3>
                <p>Nomi/Name: {data.importer_name}</p>
                <p>Manzili/Address: {data.importer_address}</p>
                <p>
                  Davlat/Country: {data.importer_country.name_local}/
                  {data.importer_country.name_en}
                </p>
                <p>
                  Davlat ISO kodi/ISO country code: {data.importer_country.code}
                </p>
              </Spacer>
            </td>
            <td colSpan={2} className="diagonalCross">
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>
                  I.6.
                  {/* Yuk uchun masʼul operator/Operator responsible for the
                  consignment */}
                </h3>
                {/* <p>Nomi/Name: DALIAN GANGHUA INTERNATIONAL TRADE CO., LTD.</p>
                <p>
                  Manzili/Address: RM.54-55,5/F,NO.82 MINGZE STREET, ZHONGSHAN
                  DIST.DALIAN,LIAONING, CHINA
                </p>
                <p>Davlat/Country: O'zbekiston Respublikasi</p>
                <p>Davlat ISO kodi/ISO country code: Uz</p> */}
              </Spacer>
            </td>
          </tr>

          <tr>
            <td colSpan={2}>
              <Spacer>
                <div>
                  <h3 style={{ fontWeight: "bold", paddingBottom: "4px" }}>
                    I.7. Kelib chiqish davlati/Country of origin:
                  </h3>
                  <p>O'zbekiston Respublikasi/Republic of Uzbekistan</p>
                </div>
                <p>Davlat ISO kodi/ISO country code: UZ</p>
              </Spacer>
            </td>
            <td colSpan={2}>
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>
                  I.8 Kelib chiqish hududi/Region of origin:
                </h3>
                <p>
                  {data?.products
                    ?.map(
                      (p) => `${p?.origin_region?.name_en} ${p?.origin_place}`
                    )
                    .join(", ")}
                </p>
              </Spacer>
            </td>
          </tr>

          <tr>
            <td colSpan={2}>
              <Spacer>
                <div>
                  <h3 style={{ fontWeight: "bold", marginBottom: "4px" }}>
                    I.9. Yukning yetib borish davlati/Country of destination:
                  </h3>

                  <p>
                    {data.destination_country.name_local}/
                    {data.destination_country.name_en}
                  </p>
                </div>
                <p>
                  Davlat ISO kodi/ISO country code:{" "}
                  {data.destination_country.code}
                </p>
              </Spacer>
            </td>
            <td colSpan={2} className="diagonalCross">
              <Spacer>
                <div>
                  <h3 style={{ fontWeight: "bold" }}>
                    I.10.
                    {/* Yukning yetib borish hududi/Region of destination: */}
                  </h3>

                  {/* <p>O'zbekiston Respublikasi</p> */}
                </div>
                {/* <p>Davlat ISO kodi/ISO country code: Uz</p> */}
              </Spacer>
            </td>
          </tr>

          <tr>
            <td colSpan={2}>
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>
                  I.11. Yuk jo‘natish joyi/Place of dispatch
                </h3>
                <p>
                  Nomi/Name:{" "}
                  {data?.products
                    ?.map((p) => p.origin_region.name_en)
                    ?.join(", ")}
                </p>

                <p>
                  Manzili/Address:{" "}
                  {data?.products
                    ?.map((p) => p.origin_district.name_en)
                    ?.join(", ")}
                </p>
                <div>
                  <p>
                    Davlat/Country: O'zbekiston Respublikasi/Republic of
                    Uzbekistan
                  </p>
                  <p>Davlat ISO kodi/ISO country code: UZ</p>
                </div>
              </Spacer>
            </td>

            <td colSpan={2}>
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>
                  I.12. Yukning yetib borish joyi/Place of destination
                </h3>
                <p>Nomi/Name: {data.importer_name}</p>
                <p>Manzili/Address: {data.importer_address}</p>
                <p>
                  Davlat/Country: {data.importer_country.name_local}/
                  {data.importer_country.name_en}
                </p>
                <p>
                  Davlat ISO kodi/ISO country code: {data.importer_country.code}
                </p>
              </Spacer>
            </td>
          </tr>

          <tr>
            <td colSpan={2}>
              <h3 style={{ fontWeight: "bold", paddingBottom: "4px" }}>
                I.13. Yuklanish joyi/Place of loading
              </h3>
              <p>{data.loading_place}</p>
            </td>

            <td colSpan={2}>
              <h3 style={{ fontWeight: "bold", paddingBottom: "4px" }}>
                I.14. Jo‘natish sanasi va vaqti/Date and time of departure
              </h3>
              <p>{data.departure_date}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </PrintPaperComponent>
  );
};
