import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import indev from "../../assets/icons/indev.svg";
import { CopyOutlined, LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import _ from "lodash";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import WOW from "wow.js";
import addRect from "../../assets/icons/add-rectangle.svg";
import download from "../../assets/icons/download.svg";
import saveIcon from "../../assets/icons/save.svg";
import Loader from "../../components/loader/Loader";
import request from "../../helpers/createRequest";
import ArchiveInfo from "./archive";
import AddInvoice from "./create";
import "./invoice.css";

export const antIcon = <LoadingOutlined style={{ color: "white" }} spin />;

const Invoice = () => {
  const { t } = useTranslation();
  const lang = useSelector((state) => state.language.lang);
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [openAdd, setOpenAdd] = useState(false);
  const [openArch, setOpenArch] = useState(false);

  const [detail, setDetail] = useState(null);
  const [services, setServices] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [archivedInvoices, setArchivedInvoices] = useState({});

  useEffect(() => {
    toggleMobile();
    window.addEventListener("resize", toggleMobile);
    return () => window.removeEventListener("resize", toggleMobile);
  }, []);

  const toggleMobile = () => {
    if (window.innerWidth < 610) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    new WOW().init();

    async function getArchive() {
      await request
        .get(`/applicant/archived-invoice-and-balance/list/`)
        .then((res) => {
          setArchivedInvoices(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }

    getArchive();
  }, []);

  useEffect(() => {
    async function getLists() {
      setIsLoading(true);

      await request
        .get("/applicant/invoice/list/")
        .then((res) => {
          setInvoices(res.data);
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });

      setIsLoading(false);
    }

    if (!openAdd && !openArch) {
      getLists();
    }

    getApplicantDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAdd, openArch]);

  useEffect(() => {
    async function getLists() {
      setIsLoading(true);
      await request
        .get(`/api/services/?lang=${lang}`)
        .then((res) => {
          var result = Object.keys(res.data).map((key) => {
            return { id: Number(key), value: res.data[key] };
          });
          setServices(result);
        })
        .catch((e) => {
          console.log(e);
        });

      setIsLoading(false);
    }

    if (!openAdd && !openArch) {
      getLists();
    }
  }, [openAdd, openArch, lang]);

  const getApplicantDetails = async () => {
    setIsButtonLoading(true);
    await request
      .get("/applicant/detail/")
      .then((res) => {
        setDetail(res.data);
        // console.log(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
    setIsButtonLoading(false);
  };

  const openAddHandle = () => {
    if (detail) {
      setOpenAdd(true);
    } else {
      getApplicantDetails();
      toast.warning(t("invoice.wait"));
    }
  };

  const openArchiveHandle = () => {
    if (
      !_.isEmpty(archivedInvoices.invoices) ||
      !_.isEmpty(archivedInvoices.balances)
    ) {
      setOpenArch(true);
    } else {
      toast.error("Arxivda ma'lumotlar topilmadi!");
    }
  };

  return (
    <div className="invoice-page">
      <AddInvoice
        openAdd={openAdd}
        setOpenAdd={setOpenAdd}
        serviceArrs={services}
        detail={detail}
      />
      <ArchiveInfo
        open={openArch}
        setOpen={setOpenArch}
        data={archivedInvoices}
        services={services}
      />
      <div className="top">
        <h1>{t("invoice.invoice")}</h1>
        <div className="right">
          <div style={{ display: "flex" }}>
            <div className="btn-wrap">
              <button
                className={"arch-btn"}
                onClick={openArchiveHandle}
                style={{ position: "relative" }}
              >
                <div className="badge-new"></div>
                {t("invoice.archive")}
              </button>
            </div>
            <div className="btn-wrap">
              <button
                // type="submit"
                className={"button-active"}
                // disabled={invoices.length === 7}
                onClick={openAddHandle}
              >
                {!isButtonLoading ? (
                  t("invoice.confirm-inv")
                ) : (
                  <Spin indicator={antIcon} />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? <Loader /> : null}
      {!_.isEmpty(invoices) && (
        <div className="invoice-cards">
          <div className="cards-wrapper">
            {invoices?.map((inv, idx) => {
              return (
                <div
                  className="notMobile"
                  key={idx}
                  onClick={() => {
                    if (!isMobile) window.open(inv.get_absolute_url, "_blank");
                  }}
                  role="link"
                  tabIndex={0}
                  // href={isMobile ? null : `${inv.get_absolute_url}`}
                  // target={isMobile ? "" : `_blank`}
                >
                  <div
                    className="invoice-card wow fadeInLeft"
                    data-wow-duration={`${0.1 * (idx + 1)}s`}
                    data-wow-delay={`${0.1 * (idx + 1)}s`}
                  >
                    <div className="card-wrapper">
                      <span className="invoice-name">
                        {
                          services?.filter(
                            (s, idx) => s.id == inv.service_type
                          )[0]?.value
                        }
                        <br />
                        {inv.service_type == 3
                          ? inv.region_name[`${lang}`]
                          : ""}
                      </span>
                      <div className="card-amount">
                        {inv?.balance == null ? (
                          "***"
                        ) : (
                          <>
                            <span>{inv?.balance?.toLocaleString("en-US")}</span>
                            <span>{t("invoice.sum")}</span>
                          </>
                        )}
                      </div>
                      <div className="card-bottom">
                        <div className="invoice-num">
                          <span>{t("invoice.invoice-num")}</span>
                          <span>
                            {inv?.number}{" "}
                            <span
                              className="mobile"
                              onClick={() => {
                                navigator.clipboard.writeText(inv?.number);
                                // toast.success('')
                              }}
                            >
                              <CopyOutlined />
                            </span>
                          </span>
                        </div>
                        <div className="invoice-date">
                          <span>{t("invoice.date")}</span>
                          <span>{inv?.given_date}</span>
                        </div>
                      </div>
                      <a
                        className="mobile"
                        href={`${inv.get_absolute_url}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img className="mobile" src={download} alt="download" />
                      </a>
                    </div>

                    <div className="on-hover-card">
                      <img src={saveIcon} alt="download" />
                      <p>{t("invoice.download")} (.pdf)</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {!_.isEmpty(invoices) && (
        <div className="toaster">
          <p>
            {t("instruction.payment")}{" "}
            <a
              href={"https://nbu.uz/uz/milliy/"}
              target="_blank"
              rel="noreferrer"
            >
              Milliy bank
            </a>
            ,{" "}
            <a
              href={"https://click.uz/uz/system-description-and-capabilities"}
              target="_blank"
              rel="noreferrer"
            >
              Click
            </a>
            ,{" "}
            <a
              href={"https://payme.uz/home/main"}
              target="_blank"
              rel="noreferrer"
            >
              PayMe
            </a>
            ,{" "}
            <a href={"https://www.paynet.uz/"} target="_blank" rel="noreferrer">
              PayNet
            </a>
            ,{" "}
            <a href={"https://upay.uz/"} target="_blank" rel="noreferrer">
              Upay
            </a>
          </p>
        </div>
      )}

      {_.isEmpty(invoices) && !isLoading ? (
        <div className="no-invoice">
          <div className="invoice-add-card" onClick={(e) => setOpenAdd(true)}>
            <div className="center">
              <div className="img-wrap">
                <img src={addRect} alt="img" />
              </div>
              <p>Нажмите чтобы добавить оферту</p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Invoice;
