import React from "react";
import { PrintPaperComponent } from "../../../../../../components/ui/PaperPrint";
import { Spacer, Stack } from "../../style";
import { useFormContext } from "react-hook-form";
const transport_type = [
  { value: 1, label: "Temir yo‘l/Railway" },
  { value: 2, label: "Suv/Vessel" },
  { value: 3, label: "Avia/Aircraft" },
  { value: 4, label: "Avto/Road vehicle" },
];
const transport_condition_type = [
  { value: "Tabiiy shaklida/Ambient", label: "Tabiiy shaklida/Ambient" },
  { value: "Sovutilgan/Chilled", label: "Sovutilgan/Chilled" },
  { value: "Muzlatilgan/Frozen", label: "Muzlatilgan/Frozen" },
];
export const PageTwo = () => {
  const { getValues } = useFormContext();
  const data = getValues("data");
  const calculateTotals = (data) => {
    return data?.reduce(
      (totals, item) => {
        totals.package_net_weight += item?.package_net_weight;
        totals.package_quantity += item?.package_quantity;
        totals.total_net_weight += item?.total_net_weight;
        return totals;
      },
      {
        package_net_weight: 0,
        package_quantity: 0,
        total_net_weight: 0,
      }
    );
  };
  const totals = calculateTotals(data?.products);
  return (
    <PrintPaperComponent page={2} qrCode={true}>
      <table className="pageTwo">
        <tbody>
          <tr>
            <td colSpan={2} rowSpan={2}>
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>
                  I.15. Transport vositasi/Means of transport
                </h3>
                <ul>
                  {transport_type?.map((tr) => (
                    <li
                      key={tr.value}
                      className={
                        (tr.value === data.transport_type ||
                          (data.number === "24150800001" && tr.value === 2)) &&
                        "active-text"
                      }
                    >
                      {tr.label}
                    </li>
                  ))}
                </ul>
                <div>
                  <h3 style={{ fontWeight: "bold" }}>
                    Identifikatsiya raqami/Identification No.
                  </h3>
                  <p>{data.transport_number}</p>
                </div>
              </Spacer>
            </td>
            <td colSpan={3}>
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>
                  I.16. Kirishda nazoratdan o‘tkazish chegara punkti/Point of
                  Entry (Control Point)
                </h3>

                <p>{data.number === "24150800001" && "Felixstowe"}</p>
              </Spacer>
            </td>
          </tr>

          <tr>
            <td colSpan={3}>
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>
                  I.17. Yukka tegishli hujjatlar/Accompanying documents
                </h3>
                {/* <p>Mahsulot turi/Type: ____________________</p>
                <p>Kod/Code: _______</p> */}
                <p>
                  Davlat/Country: O'zbekiston Respublikasi/Republic of
                  Uzbekistan
                </p>
                <p>Davlat ISO kodi/ISO country code: UZ</p>
                <h3 style={{ fontWeight: "bold" }}>
                  Hujjatlashtirish uchun havolalar/Documentation references
                </h3>
                {/* <p>Konosament raqami/Bill of Lading Number: ____________</p> */}
                <p>Fitosanitariya sertifikati/Phytosanitary certificate</p>
              </Spacer>
            </td>
          </tr>

          <tr>
            <td colSpan={5}>
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>
                  I.18. Transportirovka shartlari/Transport conditions
                </h3>
                <ul>
                  {transport_condition_type?.map((tt) => (
                    <li
                      key={tt.value}
                      className={
                        tt.value === data.transport_condition && "active-text"
                      }
                    >
                      {tt.label}
                    </li>
                  ))}
                </ul>
              </Spacer>
            </td>
          </tr>
          <tr>
            <td colSpan={5}>
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>
                  I.19. Konteyner raqami/plomba raqami Container number/Seal
                  number
                </h3>
                {data.container_number && <p>{data.container_number}</p>}
              </Spacer>
            </td>
          </tr>

          <tr>
            <td colSpan={5}>
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>
                  I.20. Sertifikatlash maqsadi/Certified as or for
                </h3>
                <ul>
                  <li className="active-text">
                    Inson isteʼmoli maqsadida/Products for human consumption
                  </li>
                  {/* <li>
                    Inson isteʼmoli maqsadida/Products for human consumption
                  </li>
                  <li>
                    Inson isteʼmoli maqsadida/Products for human consumption
                  </li> */}
                </ul>
              </Spacer>
            </td>
          </tr>

          <tr>
            <td colSpan={2} rowSpan={2} className="diagonalCross">
              <h3 style={{ fontWeight: "bold", minHeight: "48px" }}>I.21.</h3>
            </td>
            <td colSpan={3} className="diagonalCross">
              <p>
                <b>I.22.</b>
                {/* Ichki bozor uchun/For internal market */}
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan={3} className="diagonalCross">
              <h3 style={{ fontWeight: "bold", minHeight: "24px" }}>I.23.</h3>
            </td>
          </tr>

          <tr>
            <td colSpan={2}>
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>
                  I.24. Umumiy qadoqlar soni/Total number of packages
                </h3>
                <p>{totals.package_quantity}</p>
              </Spacer>
            </td>
            <td className="diagonalCross">
              <Spacer>
                <h3 style={{ fontWeight: "bold", width: "130px" }}>
                  I.25.
                  {/* Umumiy vazni (kg)/Total quantity (kg) */}
                </h3>
                {/* <p>12</p> */}
              </Spacer>
            </td>
            <td colSpan={2}>
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>
                  I.26. Umumiy netto vazni/brutto vazni (kg)/Total net
                  weight/gross weight (kg)
                </h3>
                <p>{totals.total_net_weight}</p>
              </Spacer>
            </td>
          </tr>

          <tr>
            <td colSpan={5}>
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>
                  I.27. Yuk tavsifi/Description of consignment
                </h3>
                {data?.products?.map((p) => (
                  <div key={p.id} className="products-list">
                    <Stack
                      style={{
                        display: "grid",
                        gap: "2px",
                        gridTemplateColumns: "repeat(4, 1fr)",
                        paddingBottom: "4px",
                      }}
                    >
                      <div>
                        <p className="title">TIF TN kodi/CN code:</p>
                        <p>{p.hs_code.code}</p>
                      </div>
                      <div>
                        <p className="title">Turi/Species:</p>
                        <p>{p.name}</p>
                      </div>

                      <div>
                        <p className="title">Partiya raqami/Batch No</p>
                        <p>{p.lot_number}</p>
                      </div>
                      <div>
                        <p className="title">
                          Yig‘ish sanasi/Date of collection:
                        </p>
                        <p>{p.collected_date}</p>
                      </div>
                    </Stack>
                    <Stack
                      style={{
                        display: "grid",
                        gap: "4px",
                        gridTemplateColumns: "repeat(3, 1fr)",
                      }}
                    >
                      <div>
                        <p className="title">Qadoq turi/Type of packaging:</p>
                        <p>
                          {p.package_unit.name_local}/{p.package_unit.name_en}
                        </p>
                      </div>
                      <div>
                        <p className="title">
                          Qadoqlar soni/Number of packages:
                        </p>
                        <p>{p.package_quantity}</p>
                      </div>
                      <div>
                        <p className="title">
                          Netto vazni (qadoq)/Net weight (package) kg:
                        </p>
                        <p>{p.package_net_weight}</p>
                      </div>
                    </Stack>
                  </div>
                ))}
              </Spacer>
            </td>
          </tr>
        </tbody>
      </table>
    </PrintPaperComponent>
  );
};
