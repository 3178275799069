export const REACT_QUERY_KEYS = {
  MINERAL_SUBSTANCES: "MINERAL_SUBSTANCES",
  MINERAL_CROPS: "MINERAL_CROPS",
  PREPARATION_TYPES: "PREPARATION_TYPES",
  PREPARATION_TYPES_MINERAL: "PREPARATION_TYPES_MINERAL",
  MINERAL_SHAPES: "MINERAL_SHAPES",
  MINERAL_MANUFACT: "MINERAL_MANUFACT",
  MINERAL_APP_LIST: "MINERAL_APP_LIST",

  LAB_EXPORT_BY_ID: "LAB_EXPORT_BY_ID",
  GET_REGIONS: "GET_REGIONS",
  GET_COMPANIES: "GET_COMPANIES",
  GET_REGION_BY_ID: "GET_REGION",
  GET_DISTRICTS: "GET_DISTRICTS",
  GET_MANDISTRICTS: "GET_MANDISTRICTS",
  GET_UNITS: "GET_UNITS",
  GET_COUNTRIES: "GET_COUNTRIES",
  GET_FEAPOINTS: "GET_FEAPOINTS",
  GET_FEAPOINTS_DIST: "GET_FEAPOINTS_DIST",
  GET_HSCODES: "GET_HSCODES",
  GET_PRODUCTCODES: "GET_PRODUCTCODES",
  GET_SEED_EXPORT: "GET_SEED_EXPORT",
  GET_SEED_EXPORT_BY_ID: "GET_SEED_EXPORT_BY_ID",
};
