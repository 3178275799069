// import { Pagination } from 'antd'
import { Empty } from "antd";
import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiSearch } from "react-icons/fi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../App";
import Loader from "../../components/loader/Loader";
import { userLogout } from "../../redux/actions/loginAction";
import { navigate } from "../../utils/navigate";
import OneRow from "./OneRow";
import { ModalButton } from "./components/layout/button";
import "./innerFcc.css";

const InnerFcc = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.login);
  const [filter, setFilter] = useState(false);
  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [numId, setNumId] = useState(0);
  const [prev, setPrev] = useState(null);
  const [next, setNext] = useState(null);
  const [allLists, setAllLists] = useState([]);

  const getList = (number) => {
    setIsLoading(true);
    setAllLists([]);
    if (number) {
      axios
        .get(`${baseURL}/applicant/local-fss/list/?number=${number}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setAllLists(res.data.results);
          setIsLoading(false);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate();
            dispatch(userLogout());
          }
        });
    } else {
      axios
        .get(`${baseURL}/applicant/local-fss/list/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsLoading(false);
          setAllLists(res.data.results);
          setNext(res.data.next);
          setPrev(res.data.previous);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate();
            dispatch(userLogout());
          }
        });
    }
  };

  const getNext = () => {
    setIsLoading(true);
    setAllLists([]);
    axios
      .get(next, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setNumId(numId + 10);
        setAllLists(res.data.results);
        setNext(res.data.next);
        setPrev(res.data.previous);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate();
          dispatch(userLogout());
        }
      });
  };

  const getPrev = () => {
    setIsLoading(true);
    setAllLists([]);
    setNumId(numId - 10);
    axios
      .get(prev, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setAllLists(res.data.results);
        setNext(res.data.next);
        setPrev(res.data.prev);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate();
          dispatch(userLogout());
        }
      });
  };

  const [searchNumber, setSearchNumber] = useState("");
  const searchList = (e) => {
    e.preventDefault();
    setAllLists([]);
    setIsLoading(true);
    axios
      .get(`${baseURL}/applicant/local-fss/list/?number=${searchNumber}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAllLists(res.data.results);
        setIsLoading(false);
        setPrev(res.data.prev);
        setNext(res.data.next);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate();
          dispatch(userLogout());
        }
      });
  };

  const [allRegions, setAllRegions] = useState([]);
  const getRegions = () => {
    axios
      .get(`${baseURL}/api/regions/`)
      .then((res) => {
        setAllRegions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [allUnits, setAllUnits] = useState([]);
  const getUnits = () => {
    axios
      .get(`${baseURL}/api/units/`)
      .then((res) => {
        setAllUnits(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getRegions();
    getList();
    getUnits();
  }, []);

  return (
    <div className="inner-fcc-my-page">
      <div className="top">
        <h1 onClick={() => getList()}>{t("navbar.inner-fcc")}</h1>
        <div className="right">
          <form className="input-wrap" onSubmit={searchList}>
            <div></div>
            <input
              type="text"
              placeholder={t("inner-fcc.search")}
              value={searchNumber}
              onChange={(e) => setSearchNumber(e.target.value)}
            />
            <MdClose
              className={searchNumber ? "icoon icoon-visible" : "icoon"}
              onClick={() => {
                setSearchNumber("");
                getList();
              }}
            />
          </form>

          <div className="btn-wrapper" style={{ display: "flex" }}>
            <div className="btn-wrap">
              <ModalButton getList={getList} />
            </div>
          </div>
        </div>
      </div>

      <div className={filter ? "filter active-filter" : "filter"}>
        <input type="date" />
        <input type="date" />
        <select>
          <option hidden>Выберите город/область</option>
          <option>Buxoro</option>
          <option>Toshkent</option>
        </select>
        <button>
          <FiSearch className="icon" />
          Поиск
        </button>
      </div>

      {isLoading ? <Loader /> : null}
      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>№</th>
              <th>{t("inner-fcc.zay-ser")}</th>
              <th>{t("inner-fcc.naim")}</th>
              <th>{t("inner-fcc.kontakt")}</th>
              <th>{t("inner-fcc.data-pol")}</th>
              <th>{t("inner-fcc.sos-ot")}</th>
              <th>{t("inner-fcc.kon-reg")}</th>
              <th>{t("inner-fcc.otp-zan")}</th>
              <th>{t("inner-fcc.prod")}</th>
            </tr>
          </thead>
          <tbody>
            {allLists?.map((item, index) => {
              const date = new Date(item.added_at);
              return (
                <OneRow
                  item={item}
                  index={index}
                  date={date}
                  key={index}
                  baseURL={baseURL}
                  allRegions={allRegions}
                  allUnits={allUnits}
                  getList={getList}
                  numId={numId}
                />
              );
            })}
          </tbody>
        </table>
      </div>

      {_.isEmpty(allLists) && !isLoading && (
        <div className="empty-table">
          <Empty
            imageStyle={{
              height: 200,
            }}
            description={<span>{t("balance.noData")}</span>}
          />
        </div>
      )}

      {!_.isEmpty(allLists) && !isLoading && (
        <div className="bottom-pagination">
          <button
            className={prev ? "" : "button-disactive"}
            onClick={getPrev}
            disabled={!prev}
          >
            <IoIosArrowBack className="icon" />
          </button>
          <button
            className={next ? "" : "button-disactive"}
            onClick={getNext}
            disabled={!next}
          >
            <IoIosArrowForward className="icon" />
          </button>
        </div>
      )}
    </div>
  );
};

export default InnerFcc;
